<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>Reporte Historial de Crédito</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
            <b-row class="justify-content-center">

              <b-col lg="6">
                  <b-form-group>
                    <label>Cliente: </label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

    

                <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <!-- <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col> -->

                

            </b-row>
          </b-form>
          
          <!-- <b-row>
            <b-col md="6">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tr>
                    <td class="text-left">Nombres</td>
                  </tr>
                </table>
              </div>
            </b-col>
          </b-row> -->

          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center" colspan="13">Creditos Pendientes</th>
                </tr>
                <tr>
                  <th rowspan="2" class="text-center">#</th>
                  <th rowspan="2" class="text-center">Fecha <br> Solicitud</th>
                  <th rowspan="2" class="text-center">Codigo</th>
                  <th rowspan="2" class="text-center">Tipo <br> Crédito</th>
                  <th rowspan="2" class="text-center">Tipo <br> Programa</th>
                  <th rowspan="2" class="text-center">Crédito</th>
                  <th rowspan="2" class="text-center">Refinanciado</th>
                  <th rowspan="2" class="text-center">TEA</th>
                  <th rowspan="2" class="text-center">Cuotas</th>
                  <th rowspan="2" class="text-center">Sede</th>
                  <th colspan="3" class="text-center">Desembolso</th>
                </tr>
                <tr>
                  <th class="text-center">Capital</th>
                  <th class="text-center">Interes</th>
                  <th class="text-center">Total</th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(item, it) in data_table.pending_credits" :key="it">
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-center">{{ item.created_at }}</td>
                  <td class="text-center">{{ item.code }}</td>
                  <td class="text-LEFT">{{ item.product_name }}</td>
                  <td class="text-left">{{ item.product_type_name }}</td>
                  <td class="text-left">{{ item.credit_plus }}</td>
                  <td class="text-center">{{ item.refinance }}</td>
                  <td class="text-right">{{ item.financial_data_tea }}%</td>
                  <td class="text-center">{{ item.dues }}</td>
                  <td class="text-left">{{ item.branch_office_name }}</td>
                  <td class="text-right">{{ item.disbursement_capital }}</td> 
                  <td class="text-right">{{ item.disbursement_interest }}</td> 
                  <td class="text-right">{{ item.disbursement_total }}</td> 
                </tr>
              </tbody>
            </table>
          </div>

          <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center" colspan="13">Creditos Aprobados</th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="text-center">#</th>
                      <th rowspan="2" class="text-center">Fecha <br> Desembolso</th>
                      <th rowspan="2" class="text-center">Codigo</th>
                      <th rowspan="2" class="text-center">Tipo <br> Crédito</th>
                      <th rowspan="2" class="text-center">Tipo <br> Programa</th>
                      <th rowspan="2" class="text-center">Crédito</th>
                      <th rowspan="2" class="text-center">Refinanciado</th>
                      <th rowspan="2" class="text-center">TEA</th>
                      <th rowspan="2" class="text-center">Cuotas</th>
                      <th rowspan="2" class="text-center">Sede</th>
                      <th colspan="3" class="text-center">Desembolso</th>
                    </tr>
                    <tr>
                      <th class="text-center">Capital</th>
                      <th class="text-center">Interes</th>
                      <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(item, it) in data_table.approved_credits" :key="it">
                      <td class="text-center">{{ it + 1 }}</td>
                      <td class="text-center">{{ item.disbursement_date }}</td>
                      <td class="text-center">{{ item.code }}</td>
                      <td class="text-LEFT">{{ item.product_name }}</td>
                      <td class="text-left">{{ item.product_type_name }}</td>
                      <td class="text-left">{{ item.credit_plus }}</td>
                      <td class="text-center">{{ item.refinance }}</td>
                      <td class="text-right">{{ item.financial_data_tea }}%</td>
                      <td class="text-center">{{ item.dues }}</td>
                      <td class="text-left">{{ item.branch_office_name }}</td>
                      <td class="text-right">{{ item.disbursement_capital }}</td> 
                      <td class="text-right">{{ item.disbursement_interest }}</td> 
                      <td class="text-right">{{ item.disbursement_total }}</td> 
                    </tr>
                  </tbody>
                </table>
          </div>
  
          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th class="text-center" colspan="13">Creditos Rechazados</th>
                </tr>
                <tr>
                  <th rowspan="2" class="text-center">#</th>
                  <th rowspan="2" class="text-center">Fecha <br> Rechazo</th>
                  <th rowspan="2" class="text-center">Codigo</th>
                  <th rowspan="2" class="text-center">Tipo <br> Crédito</th>
                  <th rowspan="2" class="text-center">Tipo <br> Programa</th>
                  <th rowspan="2" class="text-center">Crédito</th>
                  <th rowspan="2" class="text-center">Refinanciado</th>
                  <th rowspan="2" class="text-center">TEA</th>
                  <th rowspan="2" class="text-center">Cuotas</th>
                  <th rowspan="2" class="text-center">Sede</th>
                  <th colspan="3" class="text-center">Desembolso</th>
                </tr>
                <tr>
                  <th class="text-center">Capital</th>
                  <th class="text-center">Interes</th>
                  <th class="text-center">Total</th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(item, it) in data_table.rejected_credits" :key="it">
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-center">{{ item.date_approver }}</td>
                  <td class="text-center">{{ item.code }}</td>
                  <td class="text-LEFT">{{ item.product_name }}</td>
                  <td class="text-left">{{ item.product_type_name }}</td>
                  <td class="text-left">{{ item.credit_plus }}</td>
                  <td class="text-center">{{ item.refinance }}</td>
                  <td class="text-right">{{ item.financial_data_tea }}%</td>
                  <td class="text-center">{{ item.dues }}</td>
                  <td class="text-left">{{ item.branch_office_name }}</td>
                  <td class="text-right">{{ item.disbursement_capital }}</td> 
                  <td class="text-right">{{ item.disbursement_interest }}</td> 
                  <td class="text-right">{{ item.disbursement_total }}</td> 
                </tr>
              </tbody>
            </table>
          </div>

          <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center" colspan="19">Creditos Pagados</th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="text-center">#</th>
                      <th rowspan="2" class="text-center">Fecha <br> Desembolso</th>
                      <th rowspan="2" class="text-center">Codigo</th>
                      <th rowspan="2" class="text-center">Tipo <br> Crédito</th>
                      <th rowspan="2" class="text-center">Tipo <br> Programa</th>
                      <th rowspan="2" class="text-center">Crédito</th>
                  <th rowspan="2" class="text-center">Refinanciado</th>
                      <th rowspan="2" class="text-center">TEA</th>
                      <th rowspan="2" class="text-center">Cuotas</th>
                      <th rowspan="2" class="text-center">Sede</th>
                      <th colspan="3" class="text-center">Desembolso</th>
                      <th rowspan="2" class="text-center">Fecha <br> Canc.</th>
                      <th rowspan="2" class="text-center">Dias <br> Mora</th>
                      <th colspan="4" class="text-center">Pagos</th>
                    </tr>
                    <tr>
                      <th class="text-center">Capital</th>
                      <th class="text-center">Interes</th>
                      <th class="text-center">Total</th>

                      <th class="text-center">Capital</th>
                      <th class="text-center">Interes</th>
                      <th class="text-center">Mora</th>
                      <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(item, it) in data_table.credits_paid" :key="it">
                      <td class="text-center">{{ it + 1 }}</td>
                      <td class="text-center">{{ item.disbursement_date }}</td>
                      <td class="text-center">{{ item.code }}</td>
                      <td class="text-LEFT">{{ item.product_name }}</td>
                      <td class="text-left">{{ item.product_type_name }}</td>
                      <td class="text-left">{{ item.credit_plus }}</td>
                  <td class="text-center">{{ item.refinance }}</td>
                      <td class="text-right">{{ item.financial_data_tea }}%</td>
                      <td class="text-center">{{ item.dues }}</td>
                      <td class="text-left">{{ item.branch_office_name }}</td>
                      <td class="text-right">{{ item.disbursement_capital }}</td> 
                      <td class="text-right">{{ item.disbursement_interest }}</td> 
                      <td class="text-right">{{ item.disbursement_total }}</td> 
                      <td class="text-center">{{ item.last_date_paid }}</td> 

                      <td class="text-right">{{ item.days_delinquency }}</td> 
                      <td class="text-right">{{ item.paid_capital }}</td> 
                      <td class="text-right">{{ item.paid_interest }}</td> 
                      <td class="text-right">{{ item.paid_delinquency }}</td> 
                      <td class="text-right">{{ item.paid_total }}</td> 

                    </tr>
                  </tbody>
                </table>
          </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>
        table {
            width: 100%;
            border-collapse: collapse;
            overflow-x: auto; 
        }
    </style>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportCreditHistory',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      total : {
        payment_total : '0.00',
        payment_dep_bcp : '0.00',
        payment_cash : '0.00',
      },

      report:{
        id_client:'all'
      },

      client: null,
      clients: [],


      users : [],
      user: null,
      credit_type:[],
      program_type:[],
      branch_office:[],
  
      errors:{
        id_client:false,
      }
    };
  },
  mounted() {

  },
  methods: {
    SearchClients,

    Validate,
    Report,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function SearchClients(search, loading) {
  
  let me = this;
   let url = this.url_base + "client/search";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function ExportExcel() {  
  this.errors.from = this.report.from.length == 0 ? true : false;
  this.errors.to = this.report.to.length == 0 ? true : false;

 if (this.errors.from == true) { return false; }
 if (this.errors.to == true) { return false; }
  let id_user = this.user == null ? 'all':this.user.id;
  let me = this;
  let url = me.url_base + "excel/report-credit-disbursement/"+id_user+"/"+me.report.from+"/"+me.report.to+"/"+me.report.branch_office;
  window.open(url,'_blank');
}

function Validate() {

  this.errors.id_client = this.client == null ? true : false;
  if (this.errors.id_client == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  this.Report();
}


function Report() {
  let me = this;
  me.report.id_client = me.client.value;
  let data = me.report;
  let url = this.url_base + "report/credit-history";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result.credits;
        me.total = response.data.result.total;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
     .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}





</script>
